import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import StepsContext from '../../context/StepsContext';
import Booths from './Booths';
import Venues from './Venues';

export default function Steps() {
  const { event, isLoggedIn, location, booth } = useSelector(
    state => state.log
  );
  const [step, setStep] = React.useState(0);

  const handleNext = () => setStep(step + 1);

  const handleReset = () => setStep(0);

  React.useEffect(() => {
    if (Boolean(location) && !Boolean(booth)) setStep(1);
    if (!Boolean(location)) setStep(0);
  }, [location, booth]);

  return (
    <Dialog
      open={
        isLoggedIn && Boolean(event) && (!Boolean(location) || !Boolean(booth))
      }
      fullWidth
      maxWidth='xs'>
      <DialogContent>
        <StepsContext.Provider
          value={{ onNext: handleNext, onReset: handleReset }}>
          <Carousel
            showThumbs={false}
            swipeable={false}
            axis='horizontal'
            autoPlay={false}
            showArrows={false}
            centerMode={false}
            selectedItem={step}
            showIndicators={false}
            showStatus={false}>
            <Venues />
            <Booths />
          </Carousel>
        </StepsContext.Provider>
      </DialogContent>
    </Dialog>
  );
}
