import React from 'react';
import {
  Alert,
  Card,
  CardContent,
  CardMedia,
  Container,
  Skeleton,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FILE_URL, SERVER_URL } from '../../assets/constants';
import styles from './styles/EventSelection.module.css';
import { ACTIONS } from '../../states/log';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

export default function EventSelection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookie = new Cookies();

  const { isLoading, data } = useQuery({
    queryKey: ['events'],
    queryFn: () =>
      axios
        .get(`${SERVER_URL}events/selection`, { withCredentials: true })
        .then(({ data }) => data)
        .catch(e => {
          toast.error('An internal error occurred.');
        }),
  });

  return (
    <Stack>
      <Container maxWidth='md' className={styles.container}>
        <Typography textAlign='center' variant='h5' mb={2}>
          Select an event
        </Typography>
        <Grid container spacing={1} gridAutoRows='1fr' justifyContent='center'>
          {isLoading ? (
            [1, 2, 3].map(val => (
              <Grid md={4} mx='auto' key={val}>
                <Card>
                  <Skeleton variant='rectangular' height={140} />
                  <CardContent>
                    <Skeleton>
                      <Typography gutterBottom variant='h5' component='div'>
                        Event Name
                      </Typography>
                    </Skeleton>
                    <Skeleton>
                      <Typography variant='body2' color='text.secondary'>
                        No description provided.
                      </Typography>
                    </Skeleton>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <React.Fragment>
              {data?.length > 0 ? (
                data?.map(val => (
                  <Grid xs={12} md={4} key={val._id}>
                    <Card
                      className={styles.card}
                      onClick={() => {
                        cookie.set('marshalSession', val._id, {
                          domain:
                            process.env.NODE_ENV === 'production'
                              ? 'cjeventhub.com'
                              : 'localhost',
                        });
                        dispatch({
                          type: ACTIONS.ONEVENTCHANGE,
                          payload: val,
                        });
                        navigate(`/${val.name}`);
                      }}>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={`${FILE_URL}${val.image}`}
                        title={val.name}
                      />
                      <CardContent>
                        <Typography gutterBottom variant='h5' component='div'>
                          {val.name}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {val.description
                            ? val.description
                            : 'No description provided.'}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Alert severity='info' icon={false}>
                  <Typography variant='h4'>No on-going events.</Typography>
                </Alert>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </Stack>
  );
}
