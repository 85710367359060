import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_URL } from '../../assets/constants';
import styles from './styles/Location.module.css';
import NoImageIcon from '../../images/no_image_icon.png';
import Cookies from 'universal-cookie';
import { ACTIONS } from '../../states/log';
import StepsContext from '../../context/StepsContext';

export default function Booths() {
  const cookie = new Cookies();
  const { location } = useSelector(state => state.log);
  const { onReset } = React.useContext(StepsContext);
  const dispatch = useDispatch();

  const handleBooth = data => {
    cookie.set('boothSession', data._id, {
      domain:
        process.env.NODE_ENV === 'production' ? 'cjeventhub.com' : 'localhost',
    });
    dispatch({ type: ACTIONS.ONBOOTHCHANGE, payload: data });
    onReset();
  };

  return (
    <Stack p={1.5}>
      <Typography textAlign='center' variant='h6' mb={1}>
        Select Booth
      </Typography>
      <Divider />
      <Grid container gridAutoRows='1fr' spacing={1} mt={1}>
        {location?.booths?.length > 0
          ? location?.booths?.map(val => (
              <Grid xs={12} key={val._id}>
                <Card className={styles.card} onClick={() => handleBooth(val)}>
                  <CardHeader title={val.name} />
                  <CardMedia
                    image={val.image ? `${FILE_URL}${val.image}` : NoImageIcon}
                    title={val.name}
                    sx={{ height: 180 }}
                  />
                  <CardContent>
                    <Typography variant='subtitle1'>
                      {val.description ? val.description : 'No description'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          : null}
      </Grid>
    </Stack>
  );
}
