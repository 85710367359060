import {
  Box,
  Container,
  Paper,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import styles from './styles/Main.module.css';
import { FILE_URL, SERVER_URL } from '../../assets/constants';
import ChangeEvent from './ChangeEvent';
import ChangeLocation from './ChangeLocation';
import ChangeBooth from './ChangeBooth';
import Entry from '../../images/entry.png';
import Exit from '../../images/exit.png';
import Claim from '../../images/claim.png';
import React from 'react';
import { MagnifyingGlass, Watch } from 'react-loader-spinner';
import axios from 'axios';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import Moment from 'react-moment';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

export default function Scanner() {
  const { width, height } = useWindowSize();
  const { event, location, booth } = useSelector(state => state.log);
  const [service, setService] = React.useState('');
  const [count, setCount] = React.useState(15);
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const ref = React.useRef('');
  const timerRef = React.useRef();

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setCount(count => count - 1);
    }, 1000);
  };

  const handleSubmit = React.useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      setCount(15);
    }
    setIsLoading(true);
    axios
      .post(
        `${SERVER_URL}registration/booth-service/${service}`,
        { qrcode: ref.current },
        { withCredentials: true }
      )
      .then(({ data }) => {
        setData(data);
        startTimer();
        ref.current = '';
        setIsLoading(false);
      })
      .catch(e => {
        if (e.response.status === 400)
          toast.error('QR Code not affiliated with any user.');
        if (e.response.status === 406) toast.error(e.response.data);
        if (e.response.status === 422)
          toast.error('An internal error occurred.');
        if (e.response.status === 500)
          toast.error('An internal error occurred.');
        ref.current = '';
        setIsLoading(false);
      });
  }, [service]);

  const handleEvent = React.useCallback(
    ev => {
      if (ev.target.nodeName !== 'BODY') return;
      const notAllowed =
        /Shift|Tab|Enter|Alt|Control|Meta|CapsLock|Escape|F1|F2|F3|F4|F5|F6|F7|F8|F9|F10|F11|F12|Backspace|NumLock|ScrollLock|Pause|Insert|Home|PageUp|Delete|End|PageDown|ArrowUp|ArrowDown|ArrowLeft|ArrowRight/;
      if (!notAllowed.test(ev.key)) {
        ref.current += ev.key;
      }
      if (ev.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  React.useEffect(() => {
    if (service) {
      document.addEventListener('keydown', handleEvent);
    }
    return () => {
      document.removeEventListener('keydown', handleEvent);
    };
  }, [handleEvent, service]);

  React.useEffect(() => {
    if (count === 0) {
      setData(null);
      clearInterval(timerRef.current);
      setCount(15);
    }
  }, [count]);

  return (
    <Stack
      sx={{
        background:
          event?.settings?.app?.background?.datatype === 'color' &&
          event?.settings?.app?.background?.data,
        ...(event?.settings?.app?.background?.datatype === 'image' && {
          '&:before': {
            content: "''",
            position: 'fixed',
            background: `url(${FILE_URL}${event?.settings?.app?.background?.data}) no-repeat center fixed`,
            backgroundSize: '100% 100%',
            inset: 0,
            zIndex: -10,
            opacity: 0.95,
          },
        }),
      }}>
      <Container maxWidth='md' className={styles.container}>
        <Stack flex={1}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                component={Paper}
                elevation={3}
                p={2}
                sx={{
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    bgcolor: '#D3D3D3',
                    opacity: 0.4,
                  },
                }}>
                <Typography variant='h5' fontWeight='bold' zIndex={1}>
                  {event?.name}
                </Typography>
                <ChangeEvent />
              </Stack>
            </Grid>
            <Grid xs={6}>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                component={Paper}
                elevation={3}
                p={2}
                sx={{
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    bgcolor: '#D3D3D3',
                    opacity: 0.4,
                  },
                }}>
                <Typography variant='h5' fontWeight='bold' zIndex={1}>
                  {location?.name}
                </Typography>
                <ChangeLocation />
              </Stack>
            </Grid>
            <Grid xs={6}>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                component={Paper}
                elevation={3}
                p={2}
                sx={{
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    bgcolor: '#D3D3D3',
                    opacity: 0.4,
                  },
                }}>
                <Typography variant='h5' fontWeight='bold' zIndex={1}>
                  {booth?.name}
                </Typography>
                <ChangeBooth />
              </Stack>
            </Grid>
          </Grid>
          {service ? (
            <Stack
              component={Paper}
              elevation={3}
              flex={1}
              my={1}
              justifyContent='center'
              alignItems='center'
              sx={{
                position: 'relative',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  bgcolor: '#D3D3D3',
                  opacity: 0.4,
                },
              }}>
              {data ? (
                <Stack direction='row' gap={1} width='100%' p={1} zIndex={5}>
                  <img
                    src={`${FILE_URL}${data?.image}`}
                    alt='User'
                    className={styles.image}
                  />
                  <Box flex={1}>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      mb={1}>
                      <Typography variant='h5' textAlign='center'>
                        {data?.firstname} {data?.lastname}
                      </Typography>
                      {data?.qrcode && (
                        <QRCode value={data?.qrcode} size={30} />
                      )}
                    </Stack>
                    <Grid container spacing={1}>
                      <Grid xs={4}>
                        <Typography variant='subtitle1'>PRC Number:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography variant='subtitle1'>
                          {data?.user_id}
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography variant='subtitle1'>
                          Classification:
                        </Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography variant='subtitle1'>
                          {data?.isDoctor ? 'Doctor' : 'Reckitt'}
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography variant='subtitle1'>Venue:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography variant='subtitle1'>
                          {data?.venue?.name}
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography variant='subtitle1'>
                          Registration Time:
                        </Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography variant='subtitle1'>
                          <Moment format='hh:mm:ss a'>{data?.createdAt}</Moment>
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Typography variant='subtitle1'>Scanned By:</Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography variant='subtitle1'>
                          {data?.scanner?.firstname} {data?.scanner?.lastname}
                        </Typography>
                      </Grid>
                    </Grid>
                    <img
                      src={`${FILE_URL}${data?.signature}`}
                      alt='Signature'
                      className={styles.signature}
                    />
                  </Box>
                </Stack>
              ) : isLoading ? (
                <Stack
                  justifyContent='center'
                  alignItems='center'
                  gap={1}
                  flex={1}
                  width='100%'
                  sx={{
                    position: 'relative',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      inset: 0,
                      bgcolor: '#D3D3D3',
                      opacity: 0.4,
                    },
                  }}>
                  <Watch
                    height='120'
                    width='120'
                    radius='48'
                    color='#4fa94d'
                    ariaLabel='watch-loading'
                    visible={true}
                  />
                  <Typography variant='h6' sx={{ color: '#4fa94d' }}>
                    Fetching data
                  </Typography>
                </Stack>
              ) : (
                <React.Fragment>
                  <img
                    src={`${FILE_URL}${event?.settings?.app?.logo}`}
                    alt='logo'
                    className={styles.logo}
                    style={{ zIndex: 5 }}
                  />
                  <Box className={styles.loader} sx={{ zIndex: 5 }}>
                    <MagnifyingGlass
                      visible={true}
                      height='120'
                      width='120'
                      ariaLabel='MagnifyingGlass-loading'
                      glassColor='#c0efff33'
                      color='#e15b64'
                    />
                  </Box>
                </React.Fragment>
              )}
            </Stack>
          ) : (
            <Stack
              flex={1}
              py={1}
              gap={1}
              justifyContent='center'
              sx={{
                position: 'relative',
              }}>
              {booth?.services?.map(val => (
                <Stack
                  key={val}
                  component={Paper}
                  alignItems='center'
                  justifyContent='center'
                  flex={1}
                  elevation={3}
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      inset: 0,
                      background: `url(${
                        val === 'ENTRY'
                          ? Entry
                          : val === 'EXIT'
                          ? Exit
                          : val === 'CLAIM'
                          ? Claim
                          : null
                      }) center no-repeat`,
                      backgroundSize: 'cover',
                      opacity: 0.7,
                      borderRadius: 1,
                      my: 'auto',
                      maxHeight: 500,
                    },
                  }}
                  onClick={() => setService(val)}>
                  <Typography
                    variant='h5'
                    zIndex={1}
                    sx={{
                      backgroundColor: 'white',
                      p: 1,
                      borderRadius: 2,
                      fontWeight: 'bold',
                      boxShadow: 2,
                    }}>
                    {val === 'ENTRY'
                      ? 'BOOTH ENTRY'
                      : val === 'EXIT'
                      ? 'BOOTH EXIT'
                      : val === 'CLAIM'
                      ? 'CLAIM FREEBIE'
                      : null}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      </Container>
      {data && service === 'CLAIM' && (
        <Confetti height={height} width={width} />
      )}
    </Stack>
  );
}
