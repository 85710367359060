import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ACTIONS } from '../../states/log';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

export default function ChangeLocation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const cookie = new Cookies();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleChange = () => {
    dispatch({ type: ACTIONS.ONRESETLOCATION, payload: null });
    cookie.remove('locationSession', {
      domain:
        process.env.NODE_ENV === 'production' ? 'cdeventshub.com' : 'localhost',
    });
    cookie.remove('boothSession', {
      domain:
        process.env.NODE_ENV === 'production' ? 'cdeventshub.com' : 'localhost',
    });
    navigate('/', { replace: true });
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        variant='contained'
        color='secondary'
        size='small'>
        Change
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle>Change Venue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change venue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size='small'>
            cancel
          </Button>
          <Button onClick={handleChange} variant='contained' size='small'>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
