import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './views/auth/Login';
import EventSelection from './views/selection/EventSelection';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from './states/log';
import { SERVER_URL } from './assets/constants';
import { toast, ToastContainer } from 'react-toastify';
import React from 'react';
import axios from 'axios';
import { Stack, Typography } from '@mui/material';
import { RotatingLines } from 'react-loader-spinner';
import styles from './App.module.css';
import Steps from './views/location/Steps';
import Cookies from 'universal-cookie';
import Scanner from './views/main/Scanner';

function App() {
  const dispatch = useDispatch();
  const { isLoggedIn, event } = useSelector(state => state.log);
  const [isLoading, setIsLoading] = React.useState(true);
  const cookie = new Cookies();

  if (event) {
    document.title = `${event?.name} - Marshals Page`;
  }

  React.useLayoutEffect(() => {
    (() => {
      setIsLoading(true);
      axios
        .get(`${SERVER_URL}auth/get-session`, { withCredentials: true })
        .then(data => {
          if (data.status === 204) dispatch({ type: ACTIONS.ONLOGOUT });
          if (data.status === 200)
            dispatch({ type: ACTIONS.ONLOGIN, payload: data.data.admin });
          if (data.data.event)
            dispatch({
              type: ACTIONS.ONEVENTCHANGE,
              payload: data.data.event,
            });
          if (data.data.location)
            dispatch({
              type: ACTIONS.ONLOCATIONCHANGE,
              payload: data.data.location,
            });
          if (data.data.booth)
            dispatch({ type: ACTIONS.ONBOOTHCHANGE, payload: data.data.booth });
          setIsLoading(false);
        })
        .catch(e => {
          toast.error('An internal error occurred.');
          setIsLoading(false);
        });
    })();
  }, [dispatch]);

  return isLoading ? (
    <Stack className={styles.loading}>
      <Stack gap={2} alignItems='center'>
        <RotatingLines
          strokeColor='black'
          strokeWidth='5'
          animationDuration='0.75'
          width='140'
          visible={true}
        />
        <Typography variant='h5'>Checking sessions...</Typography>
      </Stack>
    </Stack>
  ) : (
    <React.Fragment>
      <ToastContainer
        autoClose={2000}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        closeButton={false}
      />
      <Steps />
      <Routes>
        <Route
          element={
            isLoggedIn ? (
              !cookie.get('marshalSession') ? (
                <EventSelection />
              ) : (
                <Navigate to={`/${event.name}`} />
              )
            ) : (
              <Login />
            )
          }
          path='/'
        />
        <Route
          element={
            cookie.get('marshalSession') ? (
              isLoggedIn ? (
                <Scanner />
              ) : (
                <Navigate to='/' />
              )
            ) : (
              <Navigate to='/' />
            )
          }
          path='/:name'
        />
      </Routes>
    </React.Fragment>
  );
}

export default App;
