import {
  Stack,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Unstable_Grid2 as Grid,
  Divider,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_URL } from '../../assets/constants';
import { ACTIONS } from '../../states/log';
import styles from './styles/Location.module.css';
import Cookies from 'universal-cookie';
import NoImageIcon from '../../images/no_image_icon.png';
import StepsContext from '../../context/StepsContext';

export default function Venues() {
  const cookie = new Cookies();
  const { event } = useSelector(state => state.log);
  const { onNext } = React.useContext(StepsContext);
  const dispatch = useDispatch();

  const handleLocation = data => {
    cookie.set('locationSession', data._id, {
      domain:
        process.env.NODE_ENV === 'production' ? 'cjeventhub.com' : 'localhost',
    });
    dispatch({ type: ACTIONS.ONLOCATIONCHANGE, payload: data });
    onNext();
  };

  return (
    <Stack p={1.5}>
      <Typography textAlign='center' variant='h6' mb={1}>
        Select Venue
      </Typography>
      <Divider />
      <Grid container gridAutoRows='1fr' spacing={1} mt={1}>
        {event?.venues?.length > 0
          ? event?.venues?.map(val => (
              <Grid xs={12} key={val._id}>
                <Card
                  className={styles.card}
                  onClick={() => handleLocation(val)}>
                  <CardHeader title={val.name} />
                  <CardMedia
                    image={val.image ? `${FILE_URL}${val.image}` : NoImageIcon}
                    title={val.name}
                    sx={{ height: 180 }}
                  />
                  <CardContent>
                    <Typography variant='subtitle1'>
                      {val.description ? val.description : 'No description'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          : null}
      </Grid>
    </Stack>
  );
}
