import {
  ErrorRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SERVER_URL } from '../../assets/constants';
import Logo from '../../images/logo.png';
import { ACTIONS } from '../../states/log';
import styles from './styles/Auth.module.css';

const INITIAL_STATE = {
  email: '',
  password: '',
};

export default function Login() {
  const dispatch = useDispatch();
  const [data, setData] = React.useState(INITIAL_STATE);
  const [visible, setVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const handleInput = e =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleVisible = () => setVisible(!visible);

  const handleReset = () => setData(INITIAL_STATE);

  const handleSubmit = e => {
    e.preventDefault();
    setErrors(null);
    setIsLoading(true);
    axios
      .post(`${SERVER_URL}auth/sign-in`, data, { withCredentials: true })
      .then(({ data }) => {
        dispatch({ type: ACTIONS.ONLOGIN, payload: data });
        setIsLoading(false);
      })
      .catch(e => {
        if (e.response.status === 422) setErrors(e.response.data);
        if (e.response.status === 500)
          toast.error('An internal error occurred.');
        setIsLoading(false);
      });
  };

  return (
    <Stack minHeight='100vh'>
      <Container maxWidth='xs' className={styles.container}>
        <form onSubmit={handleSubmit}>
          <img src={Logo} alt='logo' className={styles.logo} />
          <Stack gap={1.5} py={1}>
            <TextField
              label='Email address'
              name='email'
              value={data.email}
              onInput={handleInput}
              error={Boolean(errors)}
              helperText={errors?.find(obj => obj.param === 'email')?.msg}
              InputProps={{
                ...(Boolean(errors) && {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <ErrorRounded color='error' />
                    </InputAdornment>
                  ),
                }),
              }}
            />
            <TextField
              type={visible ? 'text' : 'password'}
              label='Password'
              name='password'
              onInput={handleInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {Boolean(errors) && <ErrorRounded color='error' />}
                    <IconButton onClick={handleVisible}>
                      {visible ? (
                        <VisibilityRounded />
                      ) : (
                        <VisibilityOffRounded />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors)}
              helperText={errors?.find(obj => obj.param === 'password')?.msg}
            />
            <Button
              type='submit'
              variant='contained'
              color='success'
              disabled={isLoading}
              {...(isLoading && { endIcon: <CircularProgress size={14} /> })}>
              log in
            </Button>
            <Button type='reset' onClick={handleReset}>
              reset
            </Button>
          </Stack>
        </form>
      </Container>
    </Stack>
  );
}
